"use strict";

import {findIn, findAllIn, on, addClass, hasClass, removeClass,closest} from "@c0necto/elements-dom-utils";
import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {clearAll} from "@c0necto/elements-alert-notification";
import {openOverlay} from "./overlay-toggle";

export function init () {

    onFind('.js-pool-enquiry', function (element) {
        let poolEnquiry = closest('.js-pool-enquiry',element);

        let enquiryButtons = findAllIn('.js-pool-enquiry__btn', poolEnquiry);

        enquiryButtons.forEach((btn) => {
            on('click', (evt) => enquiryBtnClickHandler(evt, btn), btn);
        });


        const enquiryBtnClickHandler = (evt, btn) => {

            // remove max items notificaton
            clearAll();

            if(!hasClass('active',btn)){
                let overlayToggle = findIn('.js-overlay__toggle', poolEnquiry);

                addClass('blink',overlayToggle);

                setTimeout(function(){
                    removeClass('blink',overlayToggle);
                }, 2000);

                let overlay = findIn('.js-overlay',element);
                openOverlay(overlay);

            }


        };


    });

}