import * as polyfills from "./scripts/polyfills/ie";

import './scripts/common';

import {onFind} from "@c0necto/elements-init-modules-in-scope";

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

import {translate} from '@c0necto/elements-translations';

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import {on} from "@c0necto/elements-dom-utils";

import * as overlayToggle from './scripts/overlay-toggle';
overlayToggle.init();

import * as catalogPoolEnquiryToggle from './scripts/catalog-pool-enquiry';
catalogPoolEnquiryToggle.init();

import * as poolEnquiry from '@c0necto/elements-pool-enquiry';
poolEnquiry.init({
    renderFunctions: {
        "inputs": {
            'item':(data) => {
                let markupElementInput = document.createElement('div');
                markupElementInput.innerHTML = `<input class="" name="catalog-enquiries[]" id="" value="${data.id}" hidden>`;

                return markupElementInput.childNodes[0];
            }
        },
        "result": {
            'item': (data, onRemove, baseElement) => {
                let markupElementItem = document.createElement('div');
                let markupElementRemoveButton = document.createElement('div');

                markupElementItem.innerHTML = `<div class="catalog-teaser catalog-teaser--enquiry vertical-gutter__item"><div class="row row-gutter--0 align-items-center"><div class="col-3"><img src="${data.imgSrc}" class="img-fluid" alt="Katalog"></div><div class="col-9"><div class="catalog-teaser__body"><div class="h4 catalog-teaser__title text-primary">${data.title}</div></div></div></div></div>`;
                markupElementRemoveButton.innerHTML = '<button class="btn btn-remove"><span class="icon icon-plus" aria-label="remove"></button>';

                const removeClickHandler = evt => {
                    onRemove(data.id, {}, baseElement);
                };

                on('click', removeClickHandler, markupElementRemoveButton.childNodes[0]);
                markupElementItem.childNodes[0].appendChild(markupElementRemoveButton.childNodes[0]);
                return markupElementItem.childNodes[0];
            },
            'fallback': `<div class="enquiry-fallback">${translate('enquiry.nothingAdded')}</div>`
        }
    },
    maxItemsNotification: {
        type: 'basic',
        title: translate('enquiry.error.title'),
        content: translate('enquiry.error.text'),
        closable: true,
        styleModifier: 'pool-enquiry-max-items-notification',
        icon: 'warning',
    }
});
